<template>
  <div class="faq">
    <div class="faq-item " v-for="(item,  index) in items" :key="`faq-item-${item.question}`"
         :class="{open: item.open || !index}">
      <component :is="item.tag" @click="item.open = !item.open" class="faq-item-question">{{ item.question }}
        <img class="faq-arrow" src="../../assets/icons/index/faq-arrow.svg" alt="">
      </component>
      <div class="faq-item-answer" v-html="item.answer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      items: [
        {
          open: true,
          tag: 'div',
          question: 'Лото Клуб онлайн – лучшее официальное казино в Казахстане',
          answer: 'Пройдите простую процедуру регистрации в Loto Club Kz и оцените все преимущества топовой игровой площадки. Большой выбор качественных игр, щедрые бонусы, превосходный сервис.'
        },
        {
          open: true,
          tag: 'h1',
          question: 'Лото Клуб: Официальный сайт в Казахстане',
          answer: 'Лото Клуб Кз – качественная игровая площадка, разработанная с применением современных технологий и с учетом требований гемблеров. Выбрав это топовое онлайн-казино, вы сможете насладиться захватывающей и прибыльной азартной игрой на лучших слотах. А новые клиенты смогут получить еще более грандиозные выигрыши, воспользовавшись щедрым приветственным предложением.'
        },
        {
          open: true,
          tag: 'h2',
          question: 'Почему стоит выбрать Лото Клуб?',
          answer: 'Современное казино Loto Club Kz – один из лучших игровых сайтов в стране, который постоянно развивается. Заведение предлагает качественный сервис и самые комфортные условия для игры. Казино имеет множество преимуществ, которые позволяет ему конкурировать с более опытными клубами.'
        },
        {
          open: true,
          tag: 'h3',
          question: 'Преимущества Лото Клуба',
          answer: `
            <div>К ключевым особенностям Loto казино, которые выгодно отличают его в сравнении с конкурентами, можно отнести:</div>
              <ul>
                <li>легальную работу на основании международной лицензии от комиссии Кюрасао;</li>
                <li>большой выбор валют, в том числе тенге, доллар, евро;</li>
                <li>современное оформление и интуитивно понятный интерфейс;</li>
                <li>огромное разнообразие качественных лото и слот игр от мировых производителей;</li>
                <li>наличие мобильного казино;</li>
                <li>щедрую бонусную программу;</li>
                <li>удобную мобильную версию;</li>
                <li>большой выбор платежных систем, в том числе: электронные кошельки, криптовалюта, интернет-банкинг;</li>
                <li>отзывчивую службу поддержки.</li>
              </ul>
              <div>Казино Лото имеет множество позитивных отзывов от реальных клиентов, что обусловлено позитивными факторами заведения. Чтобы убедиться во всех преимуществах топ казино, не обязательно регистрироваться и вносить деньги на баланс. Каждый желающий может играть в демонстрационном режиме в любое время, без финансовых рисков.</div>
          `
        },
        {
          open: true,
          tag: 'h2',
          question: 'Виды лото игр и слотов',
          answer: `
              <div>Одним из главных преимуществ любого лото онлайн казино является разнообразная и многочисленная игротека. Loto Club может похвастаться одним из лучших игровых каталогов. Оператор сотрудничает с лучшими мировыми провайдерами, среди которых такие известные студии, как: Novomatic, Amatic, Inbet Games, Igrosoft. На сайте можно найти множество классических тиражных проектов лотереи: Бинго, Бинго37, Кено, 5 из 36, 6 из 49, ТелеБинго и Авиатор. Интересные для себя варианты найдут и любители моментальных лотерей: Алтын лото, Crazy Lemon, Колесо фортуны и Plinco. Современное казино предлагает не только популярные лото. Клиенты могут запускать:</div>
              <ul>
                <li>классические и современные слоты с увлекательными сюжетами и прогрессивными джекпотами;</li>
                <li>традиционные игры: рулетку, баккару, покер, блэкджек, другие настольные развлечения;</li>
                <li>реалистичные игры с участием живого дилера;</li>
                <li>турниры и розыгрыши с крупным призовым фондом.</li>
              </ul>
              <div>Все лицензированные игровые автоматы отличаются высоким качеством. Они имеют высокий теоретический возврат и работают на правильно запрограммированном генераторе случайных чисел. Это гарантирует, что игровой процесс будет увлекательным, честным и прибыльным.</div>
          `
        },
        {
          open: true,
          tag: 'h2',
          question: 'Какие игры самые популярные?',
          answer: `
                <div>Пользователи могут выбирать карточные и настольные развлечения, лотереи, различные вариации рулетки. Самые популярные тиражные лото игры – КЕНО, 6 из 49, Донгелек, Bingo 37, Aviator crash и Aviatrix, Сапер и Мины, 12 из 24, 4 из 20. Особая гордость это игра Windjammer покер и живая vip рулетка Live.</div>
                <div>Из моментальных лотерей - Алтын, Чукча, Фруто Boom, Гараж и Лемон Крези. Барабанные слоты, которые представлены в большом ассортименте Sun of Egypt и Olympus, Hot Fruits The Gold и 777.</div>
                <div>Широко представлена тематика Египта и Фруктов, с бесплатными прокрутками Колеса удачи и проверкой фортуны на бонусный занос.</div>
          `
        },
        {
          open: true,
          tag: 'h2',
          question: 'Как начать играть в Лото Клуб?',
          answer: `Каждый посетитель Loto Club Kz онлайн может насладиться увлекательной азартной игрой в демонстрационной версии. Но играть на реальные деньги, зарабатывать на онлайн слотах, пользоваться всеми предложениями и преимуществами площадки могут только полноправные клиенты. Ознакомиться с секретами игр или поделиться своей схемой выигрыша можно в официальном канале телеграм`
        },
        {
          open: true,
          tag: 'h3',
          question: 'Регистрация на официальном сайте',
          answer: `
              <div>Чтобы открыть для себя захватывающий мир азарта, достаточно пройти простую и быструю процедуру регистрации в Лото Клуб:</div>
              <ul>
                <li>перейти на официальный портал;</li>
                <li>кликнуть кнопку регистрации, которая расположена в правом верхнем углу стартовой страницы;</li>
                <li>внести данные в электронную форму, указав номер телефона и придумав пароль для входа в личный кабинет;</li>
                <li>ознакомиться с правилами виртуального casino и подтвердить согласие с ними;</li>
                <li>подтвердить свое совершеннолетие;</li>
                <li>подтвердить регистрацию.</li>
              </ul>
              <div>После этого можно осуществить в Лото Клуб вход, используя ПК или мобильный девайс. Если официальный сайт по какой-либо причине не отвечает, следует перейти на рабочее зеркало. Для этого можно подписаться на телеграмм канал: @Loto37club или <a rel="nofollow" target="_blank" href="https://t.me/Loto37cLub">https://t.me/Loto37cLub</a>  и получать не только промо коды и бонусы, но и список доступных зеркал с новостями и секретами побед.</div>
          `
        },
        {
          open: true,
          tag: 'h2',
          question: 'Способы оплаты и депозита',
          answer: `
              <div>Еще одним шагом на пути к прибыльной азартной игре является пополнение депозита. Лото Клуб казино поддерживает все удобные и надежные платежные системы. Чаще всего для внесения средств на баланс и вывода заработанных денег игроки из Казахстана используют следующие способы оплаты:</div>
              <ul>
                <li>дебетовые и кредитные банковские карты Mastercard, Visa;</li>
                <li>банковские переводы;</li>
                <li>электронные кошельки;</li>
                <li>криптовалюту.</li>
              </ul>
              <div>Все платежные транзакции оформляются в личном кабинете. Выплаты лото клуб осуществляет мгновенно, максимальная сумма выплаты в день на одну карту 10 000 000 тенге, выигрыши больше этой суммы выплачиваются по 10 000 000 тенге в день или на несколько карточных счетов. </div>
          `
        },
        {
          open: true,
          tag: 'h2',
          question: 'Бонусы и акции Лото Клуба?',
          answer: `Казино Лото клуб онлайн радует своих клиентов щедрыми подарками. Все актуальные предложения можно найти в разделе «Бонусы». Каждый бездепозитный бонус и поощрение за внесение денег на баланс – это возможность сорвать крупный куш, играя на деньги казино. Для получения самых свежих новостей о промо акциях и бонусах, достаточно подписаться на официальный telegram канал: <a rel="nofollow" target="_blank" href="https://t.me/Loto37cLub">https://t.me/Loto37cLub</a> или следить за новостями в пуш-уведомлениях.`
        },
        {
          open: true,
          tag: 'h3',
          question: 'Приветственный бонус',
          answer: `
              <div>Первый бонус можно получить сразу после регистрации и внесения первого депозита. В приветственный пакет входят 150% от оплаченной суммы и 250 бесплатных вращений на популярные игровые автоматы. Чтобы получить бонус, необходимо пополнить счет на сумму от 2000 тенге. Максимальная сумма денежного вознаграждения – 180000 тенге.</div>
              <div>Участвовать в акции можно только один раз. Запрещено создавать несколько аккаунтов с целью повторного получения приветственного бонуса.</div>
          `
        },
        {
          open: true,
          tag: 'h3',
          question: 'Бездепозитные бонусы и промоакции',
          answer: `
              <div>Бонусная программа направлена не только на привлечение новых клиентов, но и на поддержание интереса у постоянных игроков. Лучший онлайн club в Казахстане предоставляет лояльным клиентам бездепозитные бонусы, поощрения за депозит, промокоды.</div>
              <div>В качестве бездепа можно получить кэшбек в размере до 10% от суммы проигранных ставок. Выгодное депозитное поощрение – бонус за третье пополнение счета. Каждый желающий может забрать 55 фриспинов с лояльными условиями отыгрыша.</div>
              <div>Чтобы не пропустить выгодные промоакции, подпишитесь на получение рассылки от казино <a href="https://t.me/loto37club" target="_blank">https://t.me/loto37club</a></div>
          `
        },
        {
          open: true,
          tag: 'h2',
          question: 'Безопасность и надежность Лото Клуба',
          answer: `Безопасность и надежность виртуального азартного клуба – ключевой фактор для каждого гемблера. Играть онлайн в этом казино безопасно, поскольку площадка имеет международную лицензию, использует все современные программы для защиты аккаунтов. Кроме того, на официальном сайте представлены только сертифицированные азартные игры, что гарантирует честный игровой процесс. Тиражные игры и моментальные лотерейные игры проходят государственную сертификацию и одобрены комиссией по лицензированию.`
        },
        {
          open: true,
          tag: 'h3',
          question: 'Защита данных игроков',
          answer: `Все лучшие онлайн казино особое внимание уделяют защите данных клиентов. В Лото Клуб играть онлайн можно, не переживая о сохранности персональных данных и денег на депозите. Современный оператор использует сертификаты SSL для шифрования при передаче данных на сервере. На сайте не хранится никакая финансовая информация, она передается напрямую надежным системам платежей.`
        },
        {
          open: true,
          tag: 'h2',
          question: 'Лицензии и сертификация',
          answer: `Онлайн казино Казахстана легально предоставляет услуги на основании международной лицензии. Выдана она комиссией по контролю над азартными играми в Кюрасао. Оператор отвечает всем строгим требованиям лицензии, работает в соответствии со всеми стандартами индустрии. Поэтому Лото казино официальный сайт является надежным и безопасным.`
        },
        {
          open: true,
          tag: 'h2',
          question: 'Как вывести выигрыш?',
          answer: `Casino online поддерживает множество международных платежных систем, которые можно использовать для вывода выигрышей, в частности, банковские карты, электронные, интернет-банкинг, криптовалюту. Для оформления заявки на выплату необходимо перейти в личный кабинет, в раздел «Вывести». Далее следует выбрать платежную систему, указать реквизиты, сумму и подтвердить транзакцию. Выплаты поступают моментально.`
        },
      ],
    }
  }
}
</script>


<style scoped lang="scss">
.faq {
  color: white;
  background-color: #0D0D17;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 27.8px;

  &-item {
    border-bottom: 2px solid #32334B;

    &:last-child {
      border: none;
    }

    &-question {
      font-size: 15px;
      font-weight: 600;
      line-height: 17.9px;
      padding: 17.2px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      .faq-arrow {
        transition: transform 200ms;
      }
    }

    &:first-child {
      .faq-arrow {
        display: none;
      }
    }

    &-answer {
      font-size: 12px;
      font-weight: 400;
      line-height: 13.53px;
      height: 0;
      transition: opacity 600ms;
      overflow: hidden;
      opacity: 0;
    }

    &.open {
      .faq-item-question {
        padding: 17.2px 0 10px 0;
        position: relative;

        .faq-arrow {
          transform: rotate(180deg);
        }
      }

      .faq-item-answer::v-deep {
        height: auto;
        padding-bottom: 26px;
        opacity: 1;

        div {
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        ul {
          padding-left: 18px;
          margin-top: 14px;
          margin-bottom: 14px;

          li {
            list-style: none;
            position: relative;

            &:before {
              content: "";
              border: 1px solid white;
              border-radius: 50%;
              width: 1px;
              height: 1px;
              position: absolute;
              left: -11px;
              top: 7px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
</style>